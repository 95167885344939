import { Button, Grid, ListSubheader, Popover, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import SubCard from 'ui-component/cards/SubCard';
import PropTypes from 'prop-types';
import { FilterAlt } from '@mui/icons-material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { gridSpacing } from 'store/constant';

const SkillPopover = ({ categories, onSelected, reset }) => {
    console.log(categories);
    const [selectedSkillId, setSelectedSkillId] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'skill-popover' : undefined;

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const skillSelected = (skill) => {
        setSelectedSkillId(skill.id);
        onSelected(skill);
        handlePopoverClose();
    };

    const getSkillName = (id) => {
        if (typeof id === 'number') {
            let returnValue;
            categories?.forEach((category) => {
                category?.Tags &&
                    category?.Tags.forEach((skill) => {
                        if (skill.id == id) {
                            returnValue = skill.name;
                        }
                    });
            });
            return returnValue;
        }
    };

    const clearFilters = () => {
        setSelectedSkillId('');
        onSelected(null);
        handlePopoverClose();
    };

    useEffect(() => {
        reset && setSelectedSkillId('');
    }, [reset]);

    // useEffect(() => {
    //     let preSelectedValues = JSON.parse(localStorage.getItem('preSelectedValuesInPracticePage'));
    //     if (preSelectedValues && preSelectedValues.skill) {
    //         setSelectedSkillId(preSelectedValues?.skill?.id);
    //         onSelected(preSelectedValues?.skill);
    //     }
    // }, []);

    return (
        <SubCard contentSX={{ padding: '5px !important' }}>
            <Grid container>
                <Grid item sx={{ flexGrow: 1, minWidth: 0 }}>
                    <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handlePopoverOpen}
                        sx={{
                            width: '100%',
                            minWidth: '0',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Typography sx={{ fontWeight: '500' }} noWrap>
                            {typeof selectedSkillId == 'number' ? getSkillName(selectedSkillId) : 'Filter by skill'}
                        </Typography>
                        <FilterAlt />
                    </Button>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                        }}
                    >
                        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                            <nav aria-label="skills">
                                <Grid container spacing={gridSpacing}>
                                    {categories &&
                                        categories.map((category, index) => {
                                            let tags = [];
                                            if (category.Tags && category.Tags.length) {
                                                tags = category.Tags.sort((firstItem, secondItem) => {
                                                    const tag1 = firstItem.id;
                                                    const tag2 = secondItem.id;
                                                    if (tag1 < tag2) {
                                                        return -1;
                                                    }
                                                    if (tag1 > tag2) {
                                                        return 1;
                                                    }
                                                    return 0;
                                                });
                                            }
                                            return (
                                                <>
                                                    {category.name != 'Subject Core' && category.name != 'MCQ Format' && (
                                                        <Grid item key={index}>
                                                            <List
                                                                sx={{ width: '100%', bgcolor: 'background.paper' }}
                                                                component="nav"
                                                                aria-labelledby={`${category.name}-subheader`}
                                                                subheader={
                                                                    <ListSubheader
                                                                        component="div"
                                                                        id={`${category.id}-subheader`}
                                                                        color="primary"
                                                                        sx={{ textDecoration: 'underline' }}
                                                                    >
                                                                        {category.name}
                                                                    </ListSubheader>
                                                                }
                                                            >
                                                                {tags &&
                                                                    tags.map((tag, index) => {
                                                                        return (
                                                                            <ListItem disablePadding key={index}>
                                                                                <ListItemButton
                                                                                    onClick={() => {
                                                                                        skillSelected(tag);
                                                                                    }}
                                                                                    selected={tag.name == getSkillName(selectedSkillId)}
                                                                                    sx={{ maxWidth: '250px' }}
                                                                                >
                                                                                    <ListItemText primary={tag.name} />
                                                                                </ListItemButton>
                                                                            </ListItem>
                                                                        );
                                                                    })}
                                                            </List>
                                                        </Grid>
                                                    )}
                                                </>
                                            );
                                        })}
                                </Grid>
                            </nav>
                        </Box>
                        <Button fullWidth color="error" onClick={clearFilters}>
                            Reset Filters
                        </Button>
                    </Popover>
                </Grid>
            </Grid>
        </SubCard>
    );
};

SkillPopover.propTypes = {
    skills: PropTypes.array,
    onSelected: PropTypes.func,
    reset: PropTypes.bool
};

export default SkillPopover;
