const AssessmentStatuses = {
    COMPLETE: {
        buttonText: 'Completed',
        status: 'complete',
        color: 'success'
    },
    INCOMPLETE: {
        buttonText: 'Resume',
        status: 'incomplete',
        color: 'warning'
    },
    NOT_STARTED: {
        buttonText: 'Take Assessment',
        status: 'not_started',
        color: 'primary'
    }
};

export default AssessmentStatuses;
