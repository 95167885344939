// project imports
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axiosServices from 'utils/axios';
import Assessment from 'views/ui-elements/Assessment';
import PageHeader from 'views/ui-elements/PageHeader/index.js';

// ==============================|| ACCOUNT SETTINGS PAGE ||============================== //

const TakeAssessmentPage = () => {
    const { state } = useLocation();
    const assessment = state.assessment;
    const mode = 'practice';
    const [questions, setQuestions] = useState();
    const [answers, setAnswers] = useState();

    const randomizeQuestions = (ques) => {
        const newArray = [...ques];
        for (let i = newArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = newArray[i];
            newArray[i] = newArray[j];
            newArray[j] = temp;
        }
        return newArray;
    };

    useEffect(() => {
        const getQuestionsForAssessment = async () => {
            try {
                const response = await axiosServices.get(`/questions/assessment/${assessment.id}`);
                response.data && setQuestions(randomizeQuestions(response.data));
                setAnswers([]);
            } catch (error) {
                console.log(error);
            } finally {
                console.log('close loader. job done');
            }
        };
        getQuestionsForAssessment();
    }, [assessment]);

    return (
        <>
            <PageHeader title="TakeAssessment" hasBackButton={true} />
            <Assessment questions={questions} answers={answers} mode={mode} assessmentId={assessment.id} />
        </>
    );
};

export default TakeAssessmentPage;
