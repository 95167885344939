import { Button, Grid, List, ListItem, ListItemButton, ListItemText, Popover, Typography } from '@mui/material';
import { useState } from 'react';
import SubCard from 'ui-component/cards/SubCard';
import PropTypes from 'prop-types';
import { KeyboardArrowDown } from '@mui/icons-material';
import { Box } from '@mui/system';
import { gridSpacing } from 'store/constant';
// import { useLocation } from 'react-router-dom';

const ChapterPopover = ({ chapters, onChapterSelected }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedChapterId, setSelectedChapterId] = useState('');
    const open = Boolean(anchorEl);
    const id = open ? 'chapter-popover' : undefined;
    // const location = useLocation();

    const handleMouseEnter = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMouseLeave = () => {
        setAnchorEl(null);
    };

    const handleChapterSelection = (chapter) => {
        setSelectedChapterId(chapter.id);
        onChapterSelected(chapter);
        handleMouseLeave();
    };

    const getChapterName = (id) => {
        return chapters.find((chapter) => chapter.id == id).name;
    };

    // useEffect(() => {
    //     if (location.pathname.indexOf('practice') != -1) {
    //         let preSelectedValues = JSON.parse(localStorage.getItem('preSelectedValuesInPracticePage'));
    //         const isChapterPresent = preSelectedValues ? chapters.some((chapter) => chapter.id === preSelectedValues.chapter.id) : null;
    //         if (preSelectedValues && isChapterPresent) {
    //             setSelectedChapterId(preSelectedValues.chapter.id);
    //             onChapterSelected(preSelectedValues.chapter);
    //         } else {
    //             localStorage.removeItem('preSelectedValuesInPracticePage');
    //         }
    //     } else {
    //         setSelectedChapterId('');
    //     }
    // }, []);

    return (
        <SubCard contentSX={{ padding: '5px !important' }}>
            <Grid container>
                <Grid item sx={{ flexGrow: 1, minWidth: 0 }}>
                    <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleMouseEnter}
                        sx={{
                            width: '100%',
                            minWidth: '0',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Typography sx={{ fontWeight: '500' }} noWrap>
                            {selectedChapterId ? getChapterName(selectedChapterId) : 'Pick a Chapter'}
                        </Typography>
                        <KeyboardArrowDown />
                    </Button>
                </Grid>
            </Grid>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleMouseLeave}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
            >
                <Grid container spacing={gridSpacing}>
                    <Grid item>
                        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                            <nav aria-label="chapters">
                                <List>
                                    {chapters &&
                                        chapters.map((chapter, index) => {
                                            return (
                                                <ListItem disablePadding key={index}>
                                                    <ListItemButton
                                                        onClick={() => {
                                                            handleChapterSelection(chapter);
                                                        }}
                                                        selected={chapter.id == selectedChapterId}
                                                        sx={{ maxWidth: '250px' }}
                                                    >
                                                        <ListItemText primary={chapter.name} />
                                                    </ListItemButton>
                                                </ListItem>
                                            );
                                        })}
                                </List>
                            </nav>
                        </Box>
                    </Grid>
                </Grid>
            </Popover>
        </SubCard>
    );
};

ChapterPopover.propTypes = {
    chapters: PropTypes.array,
    onChapterSelected: PropTypes.func
};

export default ChapterPopover;
